<template>
  <vx-card no-shadow>
    <div class="vs-list--header">Collaborators</div>
    <vs-table class="mb-base" stripe max-items="10" :pagination="team.length > 10" :data="team">
      <template slot="thead">
        <vs-th>Email</vs-th>
        <vs-th>Status</vs-th>
        <vs-th></vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td class="font-semibold">{{ tr.email }}</vs-td>
          <vs-td>{{ tr.status || 'N/a' }}</vs-td>
          <vs-td>
            <feather-icon
              v-if="tr.status !== 'admin'"
              icon="Trash2Icon"
              svgClasses="w-5 h-5 hover:text-primary stroke-current"
              
              @click.stop="$vs.dialog({
                color: 'danger',
                type: 'confirm',
                title: 'Cofirm Delete',
                text: 'Are you sure you want to delete this member?',
                accept: function () {
                  deleteMemberById({ id: tr.id });
                },
                acceptText: 'Yes, delete it!'
              })" />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div class="vs-list--header mb-base">Invite a new collaborator</div>
    <div class="vx-row">
      <div class="vx-col md:w-1/2">
        <div class="vx-row">
          <!-- <div class="vx-col w-full mb-5">
            <vx-field type="input" class="w-full" label="Company Name" v-model="companyName" />
          </div> -->
          <div class="vx-col w-full mb-5">
            <vx-field
              type="group"
              class="w-full"
              label="Email"
              v-model="email"
              v-validate="'required|email'">
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button color="primary" @click="inviteMember">Invite</vs-button>
                </div>
              </template>
            </vx-field>
          </div>
        </div>
      </div>
      <div class="vx-col md:w-1/2">
        <p class="text-grey mt-5">This new team member will have access to your account, except the team management, billing and payment sections.</p>
      </div>
    </div>
  </vx-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  data () {
    return {
      email: '',
      collaborators: [{
        email: 'johndoe@email.com',
        status: 'admin'
      }, {
        email: 'william@email.com',
        status: 'team member'
      }]
    }
  },
  computed: {
    ...mapState('accounts', {
      team: state => state.team.items || []
    }),
  },
  methods: {
    ...mapActions('accounts', ['deleteMemberById']),
    inviteMember() {
      this.$validator.validateAll().then(async valid => {
        if (valid) {
          await this.$store.dispatch('accounts/team', {
            email: this.email
          });
          this.email = '';
        }
      })
    }
  },
  async created() {
    await this.$store.dispatch('accounts/team');
  }
}
</script>
