<template>
  <section>
    <!-- Img Row -->
    <!-- <div class="flex flex-wrap items-center mb-base">
      <vs-avatar :src="activeUserInfo.photoURL" size="70px" class="mr-4 mb-4" />
      <div>
        <vs-button class="mr-4 sm:mb-0 mb-2">Upload photo</vs-button>
        <vs-button type="border" color="danger">Remove</vs-button>
        <p class="text-sm mt-2">Allowed JPG, GIF or PNG. Max size of 800kB</p>
      </div>
    </div> -->
    <!-- Info -->
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full mb-4">
        <vx-field type="input" class="w-full" label="First Name" v-model="form.firstName" v-validate="'required'" />
      </div>
      <div class="vx-col sm:w-1/2 w-full mb-4">
        <vx-field type="input" class="w-full" label="Last Name" v-model="form.lastName" />
      </div>
      <div class="vx-col sm:w-1/2 w-full mb-4">
        <vx-field type="input" class="w-full" label="Email" v-model="form.email" v-validate="'required|email'" />
      </div>
      <!-- <div class="vx-col sm:w-1/2 w-full mb-4">
        <vx-field type="input" class="w-full" label="Title" v-model="form.title" />
      </div>
      <div class="vx-col sm:w-1/2 w-full mb-4">
        <vx-field type="input" class="w-full" label="Mobile" v-model="form.mobile" />
      </div>
      <div class="vx-col sm:w-1/2 w-full mb-4">
        <vx-field type="input" class="w-full" label="Phone" v-model="form.phone" />
      </div> -->
      <div class="vx-col sm:w-1/2 w-full mb-4">
        <vx-field
          type="select"
          autocomplete
          class="w-full"
          label="Time Zone"
          v-model="form.timeZone"
          :options="{
            value: 'id',
            text: 'title',
            items: timezones || []
          }"
        />
          
      </div>
    </div>

    <div class="flex flex-wrap items-center justify-end mt-base">
      <vs-button class="ml-auto mt-2" @click="saveChanges">Save Changes</vs-button>
    </div>

    <!-- <vs-alert icon-pack="feather" icon="icon-info" class="h-full my-4" color="warning">
      <span>Your account is not verified. <a href="#" class="hover:underline">Resend Confirmation</a></span>
    </vs-alert> -->
  </section>
</template>

<script>
import { mapState } from 'vuex';
import Me from '@/entity/Me';
import Contact from '@/entity/Contact';
export default {
  data () {
    const me = new Me(this.$store.state.accounts.me);
    return {
      form: new Contact(me.contact)
    }
  },
  computed: {
    ...mapState({
      timezones: state => state.accounts.timezones
    })
  },
  created() {
    this.$nextTick(async () => {
      if (!this.timezones) await this.$store.dispatch('accounts/timezones');
    });
  },
  methods: {
    async saveChanges () {
      await this.$store.dispatch('accounts/me', {
        contact: this.form
      })
    }
  }
}
</script>
