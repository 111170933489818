import { loadStripe } from '@stripe/stripe-js';
import config from '@/config/stripe.json';

export default class Stripe {
  constructor(el) {
    return (async () => {
      this.$ = await loadStripe(config.key);
      const elements = this.$.elements();
    
      this.el = elements.create('card', {
        hidePostalCode: true,
        iconStyle: 'default',
        style: {
          base: {
            iconColor: '#8898AA',
            // color: 'white',
            letterSpacing: '0.01rem',
            // fontWeight: 300,
            fontFamily: '"Montserrat", Helvetica, Arial, sans-serif',
            fontSize: '1rem',

            '::placeholder': {
              fontSize: '.85rem',
              color: 'rgba(0,0,0,.7)',
            },
          },
          invalid: {
            iconColor: '#e85746',
            color: '#e85746',
          }
        },
        classes: {
          base: 'vs-inputx vs-input--input normal'
        },
      });
      this.el.mount(el);
      return this;
    })();
  }
  
  async createToken() {
    return this.$.createSource(this.el).then(result => result);
  }
}