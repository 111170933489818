<template>
  <vx-card>
    <vs-tabs position="top" class="tabs-shadow-none" id="account-tabs">
      <vs-tab icon-pack="feather" icon="icon-user" :label="!isSmallerScreen ? 'Info' : ''">
        <div class="tab-general">
          <account-info />
        </div>
      </vs-tab>
      <!-- <vs-tab icon-pack="feather" icon="icon-info" :label="!isSmallerScreen ? 'Info' : ''">
        <div class="tab-info">
          <user-settings-info />
        </div>
      </vs-tab> -->
      <vs-tab icon-pack="feather" icon="icon-credit-card" :label="!isSmallerScreen ? 'Billing' : ''">
        <div class="tab-text">
          <account-billing />
        </div>
      </vs-tab>
      <vs-tab icon-pack="feather" icon="icon-users" :label="!isSmallerScreen ? 'Team' : ''">
        <div class="tab-text">
          <account-team />
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>

<script>
/**
 * A module for account vue container
 * @module account/index
 */

import AccountInfo from './components/AccountInfo.vue'
import AccountTeam from './components/AccountTeam.vue'
import AccountBilling from './components/AccountBilling.vue'

export default {
  name: 'Account',
  components: {
    AccountInfo,
    AccountTeam,
    AccountBilling
  },
  data () {
    return {

    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  }
} 
</script>

<style lang="scss">
#account-tabs {
  .vs-tabs--content {
    padding: 20px 0;
  }
}
</style>
