<template>
  <vx-card no-shadow>
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <CreditCardForm text-submit="Add Credit Card" @source="addNewCard" />
      </div>
      <div class="vx-col md:w-1/2 w-full mb-5">
        <p class="mb-5">Your credit card information are securely stored in a vault by Stripe, our third party Payment System gateway.</p>
        <p>For easy access and retrieval, we only store the last 4 digits of your credit card and its expiration date.</p>
      </div>
    </div>
    <vs-divider class="mt-base mb-base" />
    <div class="vx-row">
      <div class="vx-col w-full">
        <PaymentCards />
      </div>
    </div>
  </vx-card>
</template>

<script>
import CreditCardForm from '@/views/account/components/components/CreditCardForm';
import PaymentCards from '@/views/account/components/components/PaymentCards';
export default {
  components: { CreditCardForm, PaymentCards },
  data: () => ({ }),
  methods: {
    async addNewCard({ source, clear }) {
      await this.$store.dispatch('order/cards', { data: { source } });
      clear();
    }
  }
}
</script>