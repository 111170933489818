<template>
  <vx-card no-shadow>
    <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="account-billing-tabs">
      <vs-tab icon-pack="feather" icon="icon-book" :label="!isSmallerScreen ? 'Billing Information' : ''">
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <AccountBillingInfo />
        </div>
      </vs-tab>

      <vs-tab icon-pack="feather" icon="icon-book" :label="!isSmallerScreen ? 'Payment Method' : ''">
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <AccountBillingPayment />
        </div>
      </vs-tab>

      <vs-tab icon-pack="feather" icon="icon-book" :label="!isSmallerScreen ? 'Invoices' : ''">
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <AccountBillingInvoice />
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>

<script>
import AccountBillingInfo from './AccountBillingInfo';
import AccountBillingPayment from './AccountBillingPayment';
import AccountBillingInvoice from './AccountBillingInvoice';

export default {
  components: {
    AccountBillingInfo,
    AccountBillingPayment,
    AccountBillingInvoice
  },
  data () {
    return { }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  }
}
</script>

<style lang="scss">
#account-billing-tabs.vs-tabs {
  .vs-tabs--content {
    padding: 0;

    .vx-card__body {
      padding-top: 10px;
    }
  }
}
</style>