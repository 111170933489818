<template>
  <div class="credit-card-form">
    <div ref="stripeElement"></div>
    <p class="text-xs text-danger mt-1" v-if="message">{{ message }}</p>
    <div class="vx-row mt-5" v-show="!disabledButton">
      <div class="vx-col w-full">
        <vs-button color="success" @click="createToken">{{ textSubmit }}</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import Stripe from '@/stripe';
export default {
  props: {
    value: {
      type: Object,
      required: false
    },
    data: {
      type: Object,
      // required: true,
      default() {
        return 
      }
    },
    textSubmit: {
      type: String,
      default: 'Save'
    },
    disabledButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasValue() {
      return this.$attrs.hasOwnProperty('value');
    },
  },
  data() {
    return {
      message: '',
      stripeService: null
    }
  },
  // watch: {
  //   form: {
  //     deep: true,
  //     handler(form) {
  //       this.$emit('input', form);
  //     }
  //   }
  // },
  methods: {
    async createToken() {
      try {
        const result = await this.stripeService.createToken();
        if (result.error) return this.message = result.error.message;
        this.message = '';
        this.$emit('source', {
          source: result.source,
          clear: this.stripeService.el.clear
        });
        return result.source;
      } catch (error) {
        this.$vs.notify({
          title: 'Stripe Element Error',
          color: 'danger',
          fixed: true
        })
      }
    },
    
  },
  created() {
    this.$validator.extend('stripe', {
      getMessage: (field, params, data) => {
        return (data || {}).message || `Your ${field} is incomplete.`
      },
      validate: value => {
        let valid = true;
        if (value && value.complete === false) valid = false;
        if (value && value.error) valid = false;
        return new Promise(resolve => {
          resolve({
            valid,
            data: !valid ? value.error : undefined
          })
        })
      }
    });
  },
  async mounted() {
    this.stripeService = await new Stripe(this.$refs.stripeElement);
    this.stripeService.el.on('change', event => {
      this.$emit('input', event);
    });
  }
}
</script>