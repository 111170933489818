<template>
  <vx-card class="billing-info-block" no-shadow>
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full mb-5">
        <vx-field type="input" class="w-full" label="Company Name" v-model="form.companyName" />
      </div>
      <div class="vx-col sm:w-1/2 w-full mb-5">
        <vx-field type="input" class="w-full" label="VAT Number" v-model="form.taxNumber" />
      </div>
      <div class="vx-col sm:w-1/2 w-full mb-5">
        <vx-field type="input" class="w-full" label="Address" v-model="address.address1" v-validate="'required'" />
      </div>
      <div class="vx-col sm:w-1/2 w-full mb-5">
        <vx-field type="input" class="w-full" label="Zip Code" v-model="address.postCode" />
      </div>
      <div class="vx-col sm:w-1/2 w-full mb-5">
        <vx-field type="input" class="w-full" label="City" v-model="address.city" />
      </div>
      <div class="vx-col sm:w-1/2 w-full mb-5">
        <vx-field
          type="select"
          autocomplete
          class="w-full"
          label="Country"
          v-model="address.countryCode"
          :options="{
            items: $countries
          }"
        />
      </div>
      <div class="vx-col sm:w-1/2 w-full mb-5">
        <vx-field type="input" class="w-full" label="Phone" v-model="form.phone" v-validate="'required'" />
      </div>
    </div>
    <div class="flex justify-end mt-base buttons">
      <vs-button @click="save">Save Changes</vs-button>
    </div>
  </vx-card>
</template>

<script>
import { mapState } from 'vuex';
import Tenant from '@/entity/Tenant';
import Address from '@/entity/Address';
export default {
  props: {
    data: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    form: new Tenant(),
    address: new Address()
  }),
  computed: {
    ...mapState('accounts', {
      tenant: state => state.tenant
    }),
    tenantData() {
      const tenant = new Tenant(this.form);
      const addresses = tenant.addresses || [];
      for (let i = 0; i < addresses.length; i++) {
        const address = addresses[i];
        if (address.id === this.address.id) {
          addresses.splice(i, 1);
        }
      }
      addresses.unshift(this.address);
      return {
        ...tenant,
        addresses
      }
    }
  },
  methods: {
    save() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.$store.dispatch('accounts/tenant', this.tenantData);
        }
      })
    }
  },
  created() {
    this.form = new Tenant(this.tenant);
    try {
      this.address = new Address(this.form.addresses[0])
    } catch (e) { return e }
  }
}
</script>