var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-card',{attrs:{"no-shadow":""}},[_c('div',{staticClass:"vs-list--header"},[_vm._v("Collaborators")]),_c('vs-table',{staticClass:"mb-base",attrs:{"stripe":"","max-items":"10","pagination":_vm.team.length > 10,"data":_vm.team},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',{staticClass:"font-semibold"},[_vm._v(_vm._s(tr.email))]),_c('vs-td',[_vm._v(_vm._s(tr.status || 'N/a'))]),_c('vs-td',[(tr.status !== 'admin')?_c('feather-icon',{attrs:{"icon":"Trash2Icon","svgClasses":"w-5 h-5 hover:text-primary stroke-current"},on:{"click":function($event){$event.stopPropagation();_vm.$vs.dialog({
              color: 'danger',
              type: 'confirm',
              title: 'Cofirm Delete',
              text: 'Are you sure you want to delete this member?',
              accept: function () {
                _vm.deleteMemberById({ id: tr.id });
              },
              acceptText: 'Yes, delete it!'
            })}}}):_vm._e()],1)],1)})}}])},[_c('template',{slot:"thead"},[_c('vs-th',[_vm._v("Email")]),_c('vs-th',[_vm._v("Status")]),_c('vs-th')],1)],2),_c('div',{staticClass:"vs-list--header mb-base"},[_vm._v("Invite a new collaborator")]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-1/2"},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full mb-5"},[_c('vx-field',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],staticClass:"w-full",attrs:{"type":"group","label":"Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}},[_c('template',{slot:"append"},[_c('div',{staticClass:"append-text btn-addon"},[_c('vs-button',{attrs:{"color":"primary"},on:{"click":_vm.inviteMember}},[_vm._v("Invite")])],1)])],2)],1)])]),_c('div',{staticClass:"vx-col md:w-1/2"},[_c('p',{staticClass:"text-grey mt-5"},[_vm._v("This new team member will have access to your account, except the team management, billing and payment sections.")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }