<template>
  <vx-card no-shadow>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-table stripe max-items="5" :pagination="invoices.length > 5" :data="invoices">
          <template slot="thead">
            <vs-th>Invoice #</vs-th>
            <vs-th>Date</vs-th>
            <vs-th>Amount</vs-th>
            <vs-th></vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>{{ tr.invoiceNumber }}</vs-td>
              <vs-td>{{ tr.invoiceDate | moment }}</vs-td>
              <vs-td>{{ tr.total | currency }}</vs-td>
              <vs-td>
                <vs-button
                  @click="download(tr)"
                  radius
                  color="success"
                  type="flat"
                  icon-pack="feather"
                  icon="icon-download">
                </vs-button>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>
  </vx-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data () {
    return {
      
    }
  },
  computed: {
    ...mapState('order', {
      invoices: state => (state.invoices || {}).items || []
    })
  },
  methods: {
    async download(tr) {
      await this.$store.dispatch('downloadFile', tr.url);
    }
  },
  async created() {
    await this.$store.dispatch('order/invoices');
  }
}
</script>