<template>
  <vs-table
    stripe max-items="5"
    :pagination="cards.length > 5"
    :data="cards.items || []"
    v-on="computedListeners">
    <template slot="thead">
      <vs-th v-show="!disabledColumns.includes('default')">Default</vs-th>
      <vs-th v-if="hasValue" class="w-1"></vs-th>
      <vs-th>Type</vs-th>
      <vs-th>Card Number</vs-th>
      <vs-th>Expiry</vs-th>
      <vs-th v-if="!hasValue"></vs-th>
    </template>
    <template slot-scope="{data}">
      <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
        <vs-tooltip text="Make Default" position="left" :active="$route.name === 'account' && !tr.isDefault">
          <vs-td class="w-1" v-show="!disabledColumns.includes('default')">
            <vs-avatar
              class="-mb-2"
              icon="icon-check"
              icon-pack="feather"
              size="small"
              :color="tr.isDefault ? 'success' : '#ccc'" />
          </vs-td>
          <vs-td v-if="hasValue" class="w-1">
            <vs-avatar
              class="-mb-2"
              icon="icon-check"
              icon-pack="feather"
              size="small"
              :color="isEqual($attrs.value, tr) ? 'success' : '#ccc'" />
          </vs-td>
          <vs-td><span class="text-sm">{{ tr.type }}</span></vs-td>
          <vs-td><span class="text-sm">**** **** **** {{ tr.cardNumber }}</span></vs-td>
          <vs-td><span class="text-sm">{{ tr.expiry | moment('MM/YY') }}</span></vs-td>
          <vs-td v-if="!hasValue">
            <vs-button
              radius
              @click.stop="deleteCard(tr)"
              color="danger"
              type="flat"
              icon-pack="feather"
              icon="icon-trash-2">
            </vs-button>
          </vs-td>
        </vs-tooltip>
      </vs-tr>
    </template>
  </vs-table>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    disabledColumns: {
      type: Array,
      default() {
        return new Array
      }
    }
  },
  data: () => ({
    selected: []
  }),
  computed: {
    ...mapState('order', ['cards']),
    hasValue() {
      return this.$attrs.hasOwnProperty('value');
    },
    isEqual() {
      return (value, tr) => {
        if (!value || !tr) return false;
        return value.id === tr.id
      }
    },
    computedListeners() {
      const listeners = this.$listeners;
      return {
        ...listeners,
        selected: listeners.selected || this.handle
      }
    },
    stripeError() {
      if (!this.hasValue) return false;
      return this.value && this.value.error;
    }
  },
  methods: {
    handle(tr) {
      if (this.hasValue) return;
      if (tr.isDefault) return;
      this.$vs.dialog({
        type: 'confirm',
        title: 'Confirm',
        text: `You're about to change your default payment method with card ending in ${tr.cardNumber}. Please click Continue to proceed.`,
        acceptText: 'Continue',
        accept: () => {
          this.$store.dispatch('order/setDefaultCard', tr);
        }
      })
    },
    preSelectDefault() {
      (this.cards.items || []).forEach(item => {
        if (item.isDefault) this.$emit('input', item);
      });
    },
    async deleteCard(card) {
      await this.$store.dispatch('order/deleteCard', card);
    }
  },
  created() {
    const me = this;
    this.$nextTick(async () => {
      try {
        /* eslint no-unused-vars: 0 */
        const cardsCount = me.cards.items.length
      } catch (error) {
        await me.$store.dispatch('order/cards');
      } finally {
        me.preSelectDefault();
      }
    })
  }

}
</script>

<style lang="scss" scoped>
.con-vs-tooltip {
  display: table-row
}
.vs-table {
  > .vs-table--tr {
    display: table-row-group;
  }
}
</style>